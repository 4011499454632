import {register} from '@ohoareau/react-widget';
import App from '@gotombola/react-widget';
import * as serviceWorker from './serviceWorker';
import createEnvSdk from '@gotombola/public-sdk';
import "./generated.css";

register({
    document,
    window,
    key: 'gotbw',
    context: {
        sdk: createEnvSdk(),
    },
    configure: async (id, {sdk}) => {
        let r;
        try {
            r = await ('d-' === id.slice(0, 2))
                ? sdk.widget.getPublicDynamicWidget(id.slice(2))
                : sdk.widget.getPublicWidget(id)
            ;
        } catch (error) {
            console.error(error)
            r = {error};
        }
        return r;
    },
    component: App,
})
    .then(() => {
        // widget loaded and rendered
    })
    .catch(e => {
        console.error(e)
    }).finally(() => {
        serviceWorker.unregister();
    })
;